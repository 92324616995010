import { Component } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { ContractService } from './_services/contract.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private contract: ContractService,
        private auth: AuthService
    ) {}
}