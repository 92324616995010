import { Component, OnInit } from "@angular/core";
import { SystemService } from "src/app/_services/system.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    year = new Date().getFullYear();

    get footImgPath(): string {
        return this.system.theme === 'light' ? '../../../assets/rf_logo_horizontal_teal.png' : '../../../assets/rf_logo_horizontal_light.png';
    }
    constructor(private system: SystemService) {}

    ngOnInit(): void {
        
    }
}