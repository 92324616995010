import { AfterViewInit, Attribute, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {deserialize, serialize, ObjectSchema }  from "airwirenft";
import { CollectionFormData } from "src/app/_pages/create/collection/collection.component";
import { ApiCollection, SchemaObject } from "src/app/_services/nft.service";

@Component({
    selector: 'app-collection-card',
    templateUrl: './collection-card.component.html',
    styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent implements AfterViewInit {
    // The collection to display
    @Input() collection?: CollectionFormData | ApiCollection;
    @Input('bannerImage') set bannerImage(val: File | undefined) {
        this._bannerImage = val ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val)) : '../../../assets/profile_banner_light.png';
    }
    @Input('iconImage') set iconImage(val: File | undefined) {
        this._iconImage = val ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val)) : '../../../assets/gradient.png';
    }

    urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    // Banner Img variables, getter, setter
    private defaultBanner = '../../../assets/profile_banner_light.png'; // Default image if none provided (should only occur with legacy collections)
    private _bannerImage?: string | SafeResourceUrl; 
    get bannerImg(): string | SafeResourceUrl {
        return this._bannerImage ? this._bannerImage : (<ApiCollection>this.collection)?.data.file ? (<ApiCollection>this.collection).data.file : this.defaultBanner;
    }

    // Icon Img variables, getter, setter
    private defaultIcon = '../../../assets/gradient.png';
    private _iconImage?: string | SafeResourceUrl;
    get iconImg(): string | SafeResourceUrl{
        if(this._iconImage) {
            return this._iconImage;
        } else {
            let result = '../../../assets/gradient.png';
            let url = (<ApiCollection>this.collection).data?.url;
    
            if(url && url.length) {
                // If it's NOT a url, means we hashed the url with the icon's CID
                if(!this.urlRegex.test(url)) {
                    let CollectionDataData: Array<SchemaObject> = [
                        {
                            name: "external",
                            type: "string"
                        },
                        {
                            name: "icon",
                            type: "string"
                        }
                    ]
                    try {
                        let deserialized = deserialize(Uint8Array.from(Buffer.from(url, 'base64')), ObjectSchema(CollectionDataData));
        
                        result = `https://ipfs.airwire.io/ipfs/${deserialized.icon}/image.png`;
                    } catch (err) {
                        return result;
                    }
                }
            } else {
                result = (<ApiCollection>this.collection).data.file;
            }
    
            this._iconImage
            return result;
        }
    }

    // If minimal, will display only collection name, bannerImg, collectionImg 
    constructor(
        @Attribute('minimal') public minimal: any,
        private sanitizer: DomSanitizer
    ) {
        this.minimal = minimal != undefined;
    }

    ngAfterViewInit(): void {

    }
}