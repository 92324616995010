import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import { Subject } from "rxjs";
import { IPFS_GATEWAY, IPFS_SUFFIX, WIRE_API } from "../_constants/constants";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { LoadingController } from "./load.service";

const DefaultSettings: Settings = {
    theme: "dark",
};

@Injectable()
export class SystemService {
    api = WIRE_API;
    isMobileSize: boolean = false;

    public settings: Settings;
    private events: any = {};

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private toast: ToastController,
        private load : LoadingController,
        private alert : AlertController,
        private clipboard : Clipboard,
        private router: Router,
        private http: HttpClient) {
            this.settings = DefaultSettings;
            let loadSettings = localStorage.getItem('settings');
            if(loadSettings) {
                const settings = JSON.parse(loadSettings);
                // console.log(this.settings);
                if(settings != null) {
                    this.theme = settings.theme;
                }
            }

            // Check if mobile screen size
            if (window.innerWidth < 900) {
                this.isMobileSize = true;
            }

            window.onresize = () => {
                if(window.innerWidth <= 900) {
                    this.isMobileSize = true;
                } else {
                    this.isMobileSize = false;
                }
            }
            
    }

    set theme(theme: 'light' | 'dark') {
        this.settings.theme = theme;
        this.setTheme(theme);
        this.saveSettings();
    }
    get theme(): 'light' | 'dark' {
        return this.settings ? this.settings.theme : DefaultSettings.theme;
    }

    post<T>(endpoint: string, data: object): Promise<T> {
        return new Promise((resolve, reject) => {
            let httpOpts: any;
            
            httpOpts = {
                // headers: new HttpHeaders ({
                //     'Content-Type': 'application/json',
                // })
            }
            
            this.http.post(this.api+endpoint, data, httpOpts).toPromise().then((response: any) => {
                resolve(response);
            }).catch((err) => {
                reject(err)
            });
        });
    }

    setTheme(theme: 'light' | 'dark') {
        if(theme === 'dark') {
            this.document.body.classList.add('dark');
        } else {
            this.document.body.classList.remove('dark');
        }
    }

    saveSettings() {
        localStorage.setItem('settings', JSON.stringify(this.settings));
    }

    async showToast(data: ToastData) {
        let buttons : any[] = []
        if (data.icon) buttons.push({ side: 'start', icon: data.icon })
        if (data.link) buttons.push({ side: 'end', text: data.linkText ? data.linkText : 'VIEW', handler: () => { this.router.navigateByUrl(data.link!) } })

        const toast = await this.toast.create({
            header: data.header,
            message: data.message,
            duration: data.duration ? data.duration : 4000,
            position: 'bottom',
            color: data.color ? data.color : 'dark',
            cssClass: 'my-toast',
            buttons
        });
        toast.present();
        return toast;
    }

    async takePicture(fullUrl? : boolean, ) : Promise<string> {
        return new Promise(async (resolve, reject)=>{
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = async (event) => {
                if(!input.files) return;
                let file = input.files[0];
                
                if (!file) {
                    reject('No file selected');
                    return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async (e) => {
                    if(!reader.result) return;
                    // console.log(reader.result);
                    let tmp = (reader.result as string).split(',')[1];
                    let newBlob = this.b64toBlob(tmp);
                    await this.uploadFile(newBlob, false).then((cid : string)=>{
                        // console.log(cid);
                        resolve(IPFS_GATEWAY + cid)
                    })
                    
                };
            };
            input.click();

            // const image = await Camera.getPhoto({
            //     quality: 100,
            //     allowEditing: true,
            //     resultType: CameraResultType.Base64,
            //     source: CameraSource.Prompt
            // });
            
            // let blob = this.b64toBlob(image.base64String, `image/${image.format}`);
            // var reader = new FileReader();

            // reader.readAsDataURL(blob);
            // reader.onload = async (e) => {
                // let url = reader.result as string;
                // let message = `<img src="${url}" class="profile-xl light-border">`
                // let header = "Upload File to IPFS"

                // const alert = await this.alert.create({
                //     cssClass: 'custom-alert',
                //     header,
                //     message,
                //     buttons: [
                //         {
                //             text: 'Upload',
                //             handler: () => {
                //                 // this.alert.dismiss()
                //                 if (loadingTag) this.emit(loadingTag)
                                // this.uploadFile(blob, false).then((cid : string)=>{
                                //     console.log(cid);
                                //     // let suffix = simple ? '' : IPFS_SUFFIX
                                //     resolve(fullUrl ? IPFS_GATEWAY + cid : cid)
                                // })
                //             }
                //         },
                //         {
                //             text: 'Cancel',
                //             role: 'cancel'
                //         }, 
                //     ],
                //     translucent: true
                // });
                // await alert.present();
        })
    }

    async uploadFile(blob : Blob, noLoad? : boolean, title: string = 'file', simple = true ): Promise<string>{
        let loading : any
        if (!noLoad){ 
            loading = await this.load.create({ spinner: 'crescent', message: `Uploading ${title} to IPFS...`, cssClass: 'loading-overlay', backdropDismiss: false });
            await loading.present();
        }

        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', blob, 'img');
            this.http.post(WIRE_API + (simple ? "/addFileSimple" : "/addFile"), data).subscribe((res : any) => {
                // console.log(res);
                if (loading) loading.dismiss()
                if (res.cid) resolve(res.cid)
                else (reject("Something went wrong..."))
            }, (err:any) => {
                if (loading) loading.dismiss()
                console.log(err);
                reject(err)
            })
        })
    }
    b64toBlob(b64Data : any, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
     
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
        
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
     
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async copyClipboard(str : string, name? : string, showMessage : boolean = false){
        this.clipboard.copy(str).then((text: string) => {}, (reject: string) => {
            if (navigator.clipboard){
                try { navigator.clipboard.writeText(str).then(() => {}); }
                catch (err){ console.log("ERR"); }
            }
        });

        const toast = await this.toast.create({
            header: name ? `${name} copied to clipboard` : `Copied to clipboard`,
            message: showMessage ? str : undefined,
            duration: 2000,
            position: 'bottom',
            color: 'success',
            cssClass: 'my-toast-copy',
            buttons: [{ side: 'end', icon: 'copy-outline' }]
        });
        toast.present();
        return toast;
    }

    social(link : string){
        if (link.includes('facebook') || link.includes('fb.me')) return 'facebook'
        else if (link.includes('twitter')) return 'twitter'
        else if (link.includes('discord')) return 'discord'
        else if (link.includes('youtube')) return 'youtube'
        else if (link.includes('instagram')) return 'instagram'
        else if (link.includes('linkedin')) return 'linkedin'
        else if (link.includes('medium')) return 'medium'
        else if (link.includes('twitch')) return 'twitch'
        else return 'link'
    }
    socialIcon(link : string){
        switch(this.social(link)){
            case 'facebook' :   return 'logo-facebook';  
            case 'twitter' :    return 'logo-twitter';
            case 'discord' :    return 'logo-discord';
            case 'youtube' :    return 'logo-youtube';
            case 'instagram' :  return 'logo-instagram';
            case 'linkedin' :   return 'logo-linkedin';
            case 'medium' :     return 'logo-medium';
            case 'twitch' :     return 'logo-twitch';
            case 'link' :       return 'link-sharp';
            default :           return 'link-sharp';
        }
    }

    on(event : string) : Subject<any> {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

interface Settings {
    theme: 'light' | 'dark';
}

export interface ToastData {
    header: string;
    message?: string;
    icon?: string;
    link?: string;
    linkText?: string;
    duration?: number;
    color?: string;
}

export interface StringDictionary {
    [key : string] : string
}