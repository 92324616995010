import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { IonSearchbar } from '@ionic/angular';
import { IPFS_GATEWAY, IPFS_SUFFIX } from 'src/app/_constants/constants';
import { LoginComponent } from 'src/app/_modals/login/login.component';
import { AuthService, User } from 'src/app/_services/auth.service';
import { ConnectService } from 'src/app/_services/connect.service';
import { ChainAccount, ContractService, GetRowData } from 'src/app/_services/contract.service';
import { ApiAsset, ApiCollection, NftService } from 'src/app/_services/nft.service';

import { SystemService } from 'src/app/_services/system.service';

const DEFAULT_RF = '../../../gradient.png'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@ViewChild('searchBar') searchBar!: ElementRef<IonSearchbar>;
    @ViewChild('myInput') myInput : any;

	connected = false;
	account?: string;

	// For mobile Nav bar - toggled when hamburger clicked will show mobile menu
	showMobileMenu: boolean = false;
	// For mobile Nav bar - toggled when mangifying glass clicked will show full search bar
	showSearchBar: boolean = false;

	users?: User[];
	collections?: ApiCollection[];
	assets?: ApiAsset[];

	searchDropdownOpen = false

	searchPhrase? : string

	pageColl = 1
	pageNft = 1
	pageUser = 1

	showingAllColl = false
	showingAllNft = false
	showingAllUser = false

	pageLimit = 4

	// Flag: to show results drop down or not.
	get showResults() {
		return this.users?.length || this.collections?.length || this.assets?.length;
	}

	get logoImgPath(): string {
        return this.system.theme === 'light' ? '../../../assets/rf_logo_horizontal_dark.png' : '../../../assets/rf_logo_horizontal_light.png';
    }

	constructor(
		public system : SystemService,
		private dialog : MatDialog,
		public auth: AuthService,
		public connect: ConnectService,
		private router: Router,
		private nftService: NftService,
		private contract : ContractService
	) { }

	ngOnInit() {
		let _this = this
		window.addEventListener('click', function(e : any){   
			if (!document.getElementById('search-container')?.contains(e.target))
				_this.searchDropdownOpen = false
		});
	}

	showSearch() { 
		this.showSearchBar = !this.showSearchBar; 
		// setTimeout(() => {
			// let elem : any= document.querySelector('.the-page-name-searchbar input');
			// if (elem) {
			// 	elem.focus();
			// }
			this.searchBar.nativeElement.setFocus()
		//   }, 500);
	}
	
	// Web3 Wallet connect
	connectWallet() {
		const dialogRef = this.dialog.open(LoginComponent, {
			panelClass: 'secondary-modal'
		})
	}

	// Light mode dark mode toggle
	toggleTheme(ev: any) {
        // console.log(ev);
        this.system.theme = this.system.theme == 'light' ? 'dark' : 'light';
    }
	// Gets appropriate ion-icon string based on dark / light mode
	get icon() : string {
		return this.system.theme == 'light' ? 'sunny-outline' : 'moon-outline'
	}

	profile() {
		this.showMobileMenu = false;
		this.router.navigate([`/profile/${this.auth.user.username}`]);
	}

	search(e : any) {
		if (e.detail.value == ''){
			this.searchDropdownOpen = false
			return
		}
		this.showingAllColl = false
		this.showingAllNft = false
		this.showingAllUser = false
		this.pageColl = 1
		this.pageNft = 1
		this.pageUser = 1
		this.searchDropdownOpen = true
		this.searchPhrase = e.detail.value;

		console.log("searchDropdownOpen For: ", this.searchPhrase);
		if(this.searchPhrase && this.searchPhrase!.length)  {
			// Only search for user / collection if the length is <= to the Wire naming limit.
			if(this.searchPhrase.length <= 12) {
				this.searchUsers()
				this.searchCollections()
			}	this.searchNfts()
		} else  this.clearResults();
    }

	searchUsers(nextPage? : boolean){
		if (nextPage) this.pageUser++
		console.log("Users page", this.pageUser);

		if (this.searchPhrase && !this.showingAllUser) this.contract.getRows({   
			scope: 'rf.users',
			contract: 'rf.users',
			table: 'users',
			lower_bound: this.searchPhrase,
			upper_bound: String.fromCharCode(this.searchPhrase!.charCodeAt(0)+1),
			limit: this.pageLimit * (nextPage ? ++this.pageUser : this.pageUser)
		})
		.then(async (data : GetRowData) => {
			console.log("User Results: ", data.rows);
			if (data.rows.length < this.pageLimit || this.users?.length == 0) this.showingAllUser = true
			this.users = data.rows
			console.log(this.users.length, this.pageLimit);
			
			if (this.users.length < this.pageLimit) this.showingAllUser = true
		}, (err:any) => {
			console.log("Error retrieving users: ", err);
		})
	}
	searchCollections(nextPage? : boolean){
		if (this.searchPhrase && !this.showingAllColl) this.nftService.getCollections({
			match: this.searchPhrase,
			page: nextPage ? ++this.pageColl : this.pageColl,
			limit: this.pageLimit,
			order: 'desc',
			sort: 'collection_name'
		}).then((results: ApiCollection[]) => {
			console.log("Collection Results: ", results);
			if (results.length < this.pageLimit || this.collections?.length == 0) this.showingAllColl = true
			if (this.collections && this.pageColl > 1) this.collections = this.collections!.concat(results)
			else this.collections = results;
		}).catch((err) => {
			console.log("Error retrieving collections: ", err);
		})
	}
	searchNfts(nextPage? : boolean){
		if (this.searchPhrase && !this.showingAllNft) this.nftService.getAssets({
			match_immutable_name: this.searchPhrase,
			page: nextPage ? ++this.pageNft : this.pageNft,
			limit: this.pageLimit,
			order: 'desc',
			sort: 'updated'
		}).then((results: ApiAsset[]) => {
			console.log("Asset Results: ", results);
			if (results.length < this.pageLimit || this.assets?.length == 0) this.showingAllNft = true
			if (this.assets && this.pageNft > 1) this.assets = this.assets!.concat(results)
			else this.assets = results;
		}).catch((err: any) => {
			console.log("Error retrieving assets: ", err);
		})	
	}

	collectionIcon(collection: ApiCollection): string {
		if(collection.data.file) return collection.data.file.replace('.alt', '');
		else return DEFAULT_RF;
	}
	imgErrorCollection(collection: ApiCollection) {
		collection.data.file = DEFAULT_RF;
	}
	routeCollection(collection: ApiCollection) {
		this.searchDropdownOpen = false
		this.router.navigate([`/collection/${collection.collection_name}`]);
		this.closeThings();
	}

	assetIcon(asset : ApiAsset): string {
		if(asset.data.file && !asset.data.file.includes('REALFRDM_Icon_square')) 
			 return IPFS_GATEWAY + asset.data.file + IPFS_SUFFIX;
		else return DEFAULT_RF;
	}
	imgErrorAsset(asset : ApiAsset) {
		asset.data.file =  DEFAULT_RF;
	}
	routeAsset(asset : ApiAsset) {
		this.searchDropdownOpen = false
		let data: NavigationExtras = { state: asset }
        this.router.navigate(['/collection', asset.collection.collection_name, asset.asset_id], data);
		this.closeThings();
	}

	userIcon(user : User): string {
		if(user.profilePic) return user.profilePic;
		else return DEFAULT_RF;
	}
	imgErrorUser(user : User) {
		user.profilePic =  DEFAULT_RF;
	}
	routeUser(user : User) {
		this.searchDropdownOpen = false
		this.router.navigate([`/profile/${user.username}`]);
		this.closeThings();
	}

	clearResults() {
		this.showingAllColl = false
		this.showingAllNft = false
		this.showingAllUser = false
		this.pageColl = 1
		this.pageNft = 1
		this.pageUser = 1
		this.showSearchBar = false
		this.showMobileMenu = false
		this.searchDropdownOpen = false
		this.searchBar.nativeElement.value = null;
		this.assets = undefined;
		this.collections = undefined;
		this.users = undefined;
	}
	closeThings() {
		this.pageColl = 1
		this.pageNft = 1
		this.pageUser = 1
		this.showSearchBar = false
		this.showMobileMenu = false
		// this.searchDropdownOpen = false
	}

	ionClearSearch(){
		if (window.innerWidth > 587) this.clearResults()
	}
	ionFocusSearch() {
		this.searchDropdownOpen = true
	}

	dismissAndConnect() {
		this.connectWallet();
		this.showMobileMenu = false;
	}
}